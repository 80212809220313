import parseFetchResponse from "./parseFetchResponse";
import { Notyf } from "notyf";
import { mutate } from "swr";

const SUBKEY = process.env.SUBKEY;
const API_BASE = process.env.API_BASE;
const notyf = new Notyf({
  duration: 5000,
  dismissible: true,
  position: { x: "right", y: "top" },
  types: [{ type: "success", background: "hsl(170, 91%, 32%)" }],
});

export async function delApi(
  url,
  method,
  payload,
  successMessage,
  mutateUrl,
  customHeaders = {},
  errorCallback,
  showToast = true
) {
  let headers = {
    "Ocp-Apim-Subscription-Key": SUBKEY,
    "Content-Type": "application/json",
    companyname: url.includes("/admin/api") ? "master" : "",
    ...customHeaders,
  };

  const options = { method, headers, body: JSON.stringify(payload) };
  if (url) {
    try {
      const response = await fetch(API_BASE + url, options);
      const data = await parseFetchResponse(response);

      if (response.ok) {
        successMessage && notyf.success(successMessage);
        mutateUrl && mutate(mutateUrl);
        return data;
      } else {
        showToast && notyf.error(data?.message || "Something went wrong");
        errorCallback && errorCallback(data);
      }
    } catch {
      notyf.error("There is no internet connection!");
    }
  } else {
    console.error("No Api URL provided");
  }
}

export default function fetcher(url) {
  return fetch(`${API_BASE}${url}`, {
    headers: {
      "Ocp-Apim-Subscription-Key": SUBKEY,
      companyname: url.includes("/admin/api") ? "master" : "",
    },
  })
    .then(async (response) => {
      const data = await parseFetchResponse(response);
      if (response.ok) {
        return data;
      } else {
        const error = new Error(`Fetch error ${data}`);
        error.status = response.status;
        throw error;
      }
    })
    .catch((e) => {
      if (e.status) {
        notyf.error("Something went wrong");
      } else if (!window.error_message_is_active) {
        window.error_message_is_active = true;
        notyf.error("There is no internet connection!");
        setTimeout(() => {
          window.error_message_is_active = false;
        }, 5000);
      }
    });
}
