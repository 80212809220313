import useUpdateEffect from "../hooks/useUpdateEffect";
import { useCallback, useState, useEffect } from "react";
import { SVELT_APPS } from "./layoutConstants";

export const useCenteredTree = (search, defaultTranslate = { x: 0, y: 0 }) => {
  const [translate, setTranslate] = useState(defaultTranslate);
  const [isInitial, setIsInitial] = useState(false);
  const [zoom] = useState(0.5);

  const resetTranslate = useCallback((val) => {
    setTranslate(val);
  }, []);

  useUpdateEffect(() => {
    if (translate.x == 0) setIsInitial(true);
    else setIsInitial(false);
  }, [translate]);

  const containerRef = useCallback(
    (containerElem) => {
      if (containerElem !== null) {
        const { height } = containerElem.getBoundingClientRect();
        const fullWidth = document.body.clientWidth;
        const fullHeight = document.body.clientHeight;

        if (search) {
          setIsInitial(false);

          setTranslate({
            x: -search.x + fullWidth / 2 - search.width / 2,
            y: -search.y + fullHeight / 2 + height / 2 - search.height / 2,
          });
        } else {
          resetTranslate({ x: 0, y: height / 2 });
        }
      }
    },
    [search, resetTranslate]
  );
  return [
    translate,
    zoom,
    containerRef,
    resetTranslate,
    isInitial,
    setIsInitial,
  ];
};

export function searchTree(element, id) {
  if (element.id === id) {
    return element;
  } else if (element.children != null) {
    var i;
    var result = null;
    for (i = 0; result == null && i < element.children.length; i++) {
      result = searchTree(element.children[i], id);
    }
    return result;
  }
  return null;
}

export function getParent(data, id, ancestor) {
  var node;

  data.some(function (n) {
    if (n.id === id) {
      node = ancestor ? ancestor : null;
      return node;
    }
    if (n.children) {
      return (node = getParent(n.children, id, n));
    }
  });
  return node || null;
}

export function usePathBuilder(data) {
  const [pathList, setPathList] = useState([]);

  useEffect(() => {
    if (data) {
      const result = [data].map(pathBuilder());
      result?.length > 0 && setPathList(result[0]);
    }
  }, [data]);

  return pathList;
}

export function pathBuilder(path, acc = []) {
  path = path || "";
  return function (o) {
    let pathObj = {
      name: path.concat(
        o.type !== "root" ? " - " + (o.name || o.Name) : o.name || o.Name
      ),
      id: o.id,
    };
    acc.push(pathObj);
    if (o.children?.length > 0) {
      o.children.map(pathBuilder(pathObj.name, acc));
    }
    return acc;
  };
}

export function usePathIdBuilder(data) {
  const [pathList, setPathList] = useState([]);

  useEffect(() => {
    if (data) {
      const result = [data].map(pathIdBuilder());
      result?.length > 0 && setPathList(result[0]);
    }
  }, [data]);

  return pathList;
}

export function pathIdBuilder(path, acc = []) {
  path = path || "";
  return function (o) {
    let pathObj = {
      name: path.concat(o.type !== "root" ? " - " + o.id : o.id),
      id: o.id,
    };
    acc.push(pathObj);
    if (o.children?.length > 0) {
      o.children.map(pathIdBuilder(pathObj.name, acc));
    }
    return acc;
  };
}

export function getLevelNodeCounts(data, key, level = 0, arr = []) {
  if (data == null) return;

  arr.push({ level, count: data[key]?.length || 0 });

  if (data[key] && data[key].length > 0) {
    data[key].forEach((d) => getLevelNodeCounts(d, key, level + 1, arr));
  }

  var holder = {};

  arr.forEach(function (d) {
    if (holder.hasOwnProperty(d.level)) {
      holder[d.level] = holder[d.level] + d.count;
    } else {
      holder[d.level] = d.count;
    }
  });

  var res = [];

  for (var prop in holder) {
    res.push({ level: +prop, count: holder[prop] });
  }

  return res;
}

export const checkIfOEEApp = () => {
  return (
    sessionStorage.getItem("appName")?.toLowerCase()?.replace(/\s/g, "") ===
    "oee"
  );
};

export const checkIfRDPMSApp = () => {
  return (
    sessionStorage.getItem("appName")?.toLowerCase()?.replace(/\s/g, "") ===
    "rdpms"
  );
};

export const clearError = (porpName, errorState, setErrorSate) => {
  const _errorState = { ...errorState };
  delete _errorState[porpName];
  setErrorSate(_errorState);
};

export const checkIfNeedOldApp = () => {
  return SVELT_APPS.includes(
    sessionStorage.getItem("appName")?.toLowerCase().replace(/\s/g, "")
  );
};

export const hasCustomApp = () => {
  const appId = sessionStorage.getItem("appId");
  const applist = JSON.parse(localStorage.getItem("applicationList") || "[]");
  const app = applist.find((a) => a.ApplicationId === appId);
  return app?.CustomAppConfig?.Enabled;
};

export const isNumber = (value) => {
  return !isNaN(value);
};

export const capitalizeFirstLetter = (string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};

export const dataToLowerCase = (value) => {
  return value.toLowerCase();
};
