import React, { useEffect, useState } from "react";
import { isNumber } from "../../lib/utils/helper";
const OTP_Lenght = 6;
const TIMER_DURATION = 30;
const OTPSection = ({
  back,
  generateOTP,
  onLogin,
  renderWrongAttempt,
  setIsLoading,
  isLoading,
  timer,
  setTimer,
}) => {
  const [otp, setOtp] = useState("");

  const onOTPSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    await onLogin(e, Number(otp));
    setIsLoading(false);
  };

  const handleBack = () => {
    back();
  };

  const handleChange = ({ target: { value } }) => {
    if (isNumber(value) && value.length <= OTP_Lenght) {
      setOtp(value);
    }
  };

  const handleResend = async () => {
    setIsLoading(true);
    const success = await generateOTP();
    if (success) {
      setTimer(TIMER_DURATION);
    }
    setIsLoading(false);
  };

  return (
    <div>
      <span className="login_headText">OTP</span>
      <form noValidate>
        <div>
          <div className={`login_input-grp mb20 txt-center`}>
            <input
              id="OTP"
              name="OTP"
              type="text"
              className={`login_otp-input`}
              onChange={handleChange}
              maxLength={OTP_Lenght}
              value={otp}
              required
            />
          </div>
          <div className="flex just-between ml_20 mr_20">
            <button
              className={`button_forgot`}
              id="backButtonFromOTP"
              type="button"
              onClick={() => {
                handleBack();
              }}
            >
              Back
            </button>
            {timer > 0 ? (
              <div>Resend in {timer}s</div>
            ) : (
              <button
                className={`button_forgot`}
                id="resendOTP"
                type="button"
                onClick={handleResend}
                disabled={isLoading}
              >
                Resend
              </button>
            )}
          </div>
        </div>
        <div className={`txt-center login_foot`}>
          <button className={`btn`} onClick={onOTPSubmit} id="submitOTPButton">
            Login
          </button>
          {renderWrongAttempt()}
        </div>
      </form>
    </div>
  );
};

export default OTPSection;
