import "./resetpassword.css";
import React, { useEffect, useMemo, useState } from "react";
import { delApi } from "../../lib/delApi";
import EyeOutline from "../../icons/eye-outline";
import EyeOffOutline from "../../icons/eyeoff-outline";
import { useLocation } from "react-router";
import GreenTick from "../../icons/green-tick";
import qs from "query-string";
import TimeOut from "../../icons/time-out";
import { useHistory } from "react-router-dom";
import { dataToLowerCase } from "../../lib/utils/helper";
import useAuth from "../../lib/hooks/useAuth";

function ResetPassword() {
  const { search: queryString } = useLocation();
  const query = useMemo(() => qs.parse(queryString), [queryString]);
  const { logout } = useAuth();

  const history = useHistory();
  const [validationResponse, setValidationResponse] = useState({
    message: "",
    status: "",
  });
  const [errors, setErrors] = useState({});
  const [warnings, setWarnings] = useState({});
  const [confirmPassword, setConfirmPassword] = useState("");
  const [resetSuccess, setResetSuccess] = useState(false);
  const [expired, setExpired] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [showResetForm, setShowResetForm] = useState(false);
  const [showPassword, setShowPassword] = useState({
    new: false,
    confirm: false,
  });
  const [showUserDialogue, setShowUserDialogue] = useState(() =>
    localStorage.getItem("uname") ? true : false
  );
  const [isLoading, setIsLoading] = useState(true);
  function onChange({ target: { name, value } }) {
    name === "newPassword" ? setNewPassword(value) : setConfirmPassword(value);
    let _errors = { ...errors };
    if (value === "") {
      setErrors((s) => ({
        ...s,
        [name]: "This field is required",
      }));
    } else {
      if (name == "newPassword") {
        const regExp = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        if (!value.match(regExp)) {
          setErrors((s) => ({
            ...s,
            [name]:
              "* Use 8 or more characters with a mix of upper cases, lower cases, numbers & symbols",
          }));
        } else {
          delete _errors[name];
          setErrors(_errors);
        }
      } else {
        delete _errors[name];
        setErrors(_errors);
      }
      let _warnings = { ...warnings };
      if (value.length >= 16) {
        setWarnings((s) => ({
          ...s,
          [name]: "* Password should not be more than 16 characters long",
        }));
      } else {
        delete _warnings[name];
        setWarnings(_warnings);
      }
    }
  }

  function validateData() {
    let _errors = { ...errors };

    if (newPassword === "") {
      _errors.newPassword = "This field is required";
    }
    if (confirmPassword === "") {
      _errors.confirmPassword = "This field is required";
    }
    if (confirmPassword && newPassword) {
      if (newPassword !== confirmPassword) {
        _errors.confirmPassword = "Passwords do not match";
      } else {
        delete _errors.confirmPassword;
      }
    }

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  function linkExpiredOrInvalid(data) {
    setShowResetForm(false);
    if (data.status === 409) {
      setValidationResponse({ ...JSON.parse(data.message) });
      setExpired(true);
    } else {
      setValidationResponse({
        title: "Oops",
        message: "Something went wrong",
      });
      setExpired(true);
    }
  }

  useEffect(() => {
    const validatelink = async (requestBody) => {
      setIsLoading(true);
      const resp = await delApi(
        `/admin/api/authentication/validatelink`,
        "POST",
        requestBody,
        null,
        null,
        null,
        (data) => {
          linkExpiredOrInvalid(data);
        },
        false
      );
      setIsLoading(false);
      return resp;
    };

    if (query) {
      const requestBody = {
        Link: encodeURIComponent(query.ed),
      };

      try {
        const res = validatelink(requestBody);
        if (res) {
          setShowResetForm(true);
        }
      } catch (error) {
        console.error("API failed", error);
      }
    }
  }, []);

  async function handleResetPassword() {
    if (validateData()) {
      const payload = {
        Link: encodeURIComponent(query.ed),
        NewPassword: newPassword,
        ConfirmPassword: confirmPassword,
      };

      try {
        const res = await delApi(
          `/admin/api/authentication/link/resetpassword`,
          "POST",
          payload,
          null,
          null,
          null,
          (data) => {
            linkExpiredOrInvalid(data);
          },
          false
        );

        if (res) {
          setResetSuccess(true);
          setShowResetForm(false);
          if (dataToLowerCase(query.rtpl) === "true") {
            setTimeout(() => {
              history.push("/");
            }, 3000);
          }
        }
      } catch (error) {
        console.error("API failed", error);
      }
    }
  }
  if (isLoading) {
    return null;
  }
  return (
    <div className={`h100 reset_container`}>
      <div className={`center reset_model   `}>
        <div className={`txt-center  reset_content`}>
          {expired ? (
            <>
              <TimeOut width="55px" height="55px" />
              <h1 className="reset_title">
                {validationResponse.title || `Oops!!`}
              </h1>
              <p className="reset_msg">
                {validationResponse.message || "Link expired"}
              </p>
            </>
          ) : showUserDialogue ? (
            <div>
              <h2>Session Active for {localStorage.getItem("uname")}</h2>
              <p>
                It seems there is an active session already open on this
                browser. Would you like to continue with this session, or
                proceed to the password reset page?
              </p>
              <div className="rest_button-group">
                <button
                  className={`btn`}
                  id="continueActiveSessionBtn"
                  onClick={() => {
                    history.replace("/");
                  }}
                >
                  Continue with Active User's Session
                </button>

                <button
                  className={`btn`}
                  id="goToPasswordResetPageBtn"
                  onClick={() => {
                    setShowUserDialogue(false);
                    logout();
                  }}
                >
                  Go to Password Reset Page
                </button>
              </div>
            </div>
          ) : (
            <>
              {resetSuccess && (
                <>
                  <h2>Password Reset Successfully</h2>
                  <GreenTick width="75px" height="75px" />
                  {dataToLowerCase(query.rtpl) === "true" ? (
                    <p>Redirecting to Login Page...</p>
                  ) : (
                    <p>Please Login to RDPMS App</p>
                  )}
                </>
              )}
              {showResetForm && (
                <>
                  {/* title */}
                  <div className="reset_title">
                    <span>Reset Your Password</span>
                    <div className="reset_note">
                      **This link expires in 15 minutes**
                    </div>
                  </div>
                  <div className="reset_input_content">
                    {/* passs */}
                    <div className={`reset_input-grp`}>
                      <label htmlFor="newPassword" className={`input-label`}>
                        New Password
                      </label>
                      <div className="input_wrapper">
                        <input
                          id="newPassword"
                          name="newPassword"
                          type={showPassword.new ? "text" : "password"}
                          className={`input `}
                          placeholder="New Password"
                          onChange={onChange}
                          required
                        />

                        {showPassword.new ? (
                          <EyeOutline
                            width="15px"
                            height="15px"
                            className="password-toggle-icon "
                            onClick={() =>
                              setShowPassword({ ...showPassword, new: false })
                            }
                          />
                        ) : (
                          <EyeOffOutline
                            width="15px"
                            height="15px"
                            className="password-toggle-icon "
                            onClick={() =>
                              setShowPassword({ ...showPassword, new: true })
                            }
                          />
                        )}
                      </div>
                      {(errors.newPassword && (
                        <p className="field-error error-text">
                          {errors.newPassword || ""}{" "}
                        </p>
                      )) ||
                        (warnings.newPassword && (
                          <p className="layout-field-warning warning-text">
                            {warnings.newPassword || ""}{" "}
                          </p>
                        ))}
                    </div>

                    {/* confirm */}
                    <div className={`reset_input-grp`}>
                      <label
                        htmlFor="confirmPassword"
                        className={`input-label `}
                      >
                        Confirm Password
                      </label>
                      <div className="input_wrapper">
                        <input
                          id="confirmPassword"
                          name="confirmPassword"
                          type={showPassword.confirm ? "text" : "password"}
                          className={`input `}
                          placeholder="Confirm Your Password"
                          value={confirmPassword}
                          onChange={onChange}
                          required
                        />

                        {showPassword.confirm ? (
                          <EyeOutline
                            width="15px"
                            height="15px"
                            cursor="pointer"
                            className="password-toggle-icon   "
                            onClick={() =>
                              setShowPassword({
                                ...showPassword,
                                confirm: false,
                              })
                            }
                          />
                        ) : (
                          <EyeOffOutline
                            width="15px"
                            height="15px"
                            cursor="pointer"
                            className="password-toggle-icon   "
                            onClick={() =>
                              setShowPassword({
                                ...showPassword,
                                confirm: true,
                              })
                            }
                          />
                        )}
                      </div>
                      {(errors.confirmPassword && (
                        <p className="field-error error-text error_field">
                          {errors.confirmPassword || ""}{" "}
                        </p>
                      )) ||
                        (warnings.confirmPassword && (
                          <p className="layout-field-warning warning-text">
                            {warnings.confirmPassword || ""}{" "}
                          </p>
                        ))}
                    </div>
                  </div>
                  {/* save */}
                  <div className={`txt-center reset_button `}>
                    <button
                      className={`btn`}
                      id="resetPasswordBtn"
                      onClick={handleResetPassword}
                    >
                      Reset Password
                    </button>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
export default ResetPassword;
